var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"fade"}},[_c('section',{ref:"playerInterface",staticClass:"interface-section",class:{ isFullScreenCta: _vm.isFullScreen }},[_c('player-title',{directives:[{name:"show",rawName:"v-show",value:(Boolean(_vm.title) && _vm.showTitle),expression:"Boolean(title) && showTitle"}]},[_vm._v(" "+_vm._s(_vm.decodeTextPlayer(_vm.title))+" ")]),(_vm.showWatermark)?_c('vidjet-watermark'):_vm._e(),(_vm.showShareButton)?_c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        text: _vm.$t('player.linkCopied'),
        trigger: 'click',
        position: 'left',
      }),expression:"{\n        text: $t('player.linkCopied'),\n        trigger: 'click',\n        position: 'left',\n      }"}],staticClass:"share-button",class:{ 'share-button-inline-carousel': !_vm.isFullScreen && _vm.isCarousel },on:{"click":_vm.shareWebPage}},[_c('Share')],1):_vm._e(),(_vm.cta && Object.keys(_vm.cta).length && _vm.isCtaWithinRange)?_c('div',{ref:"callToAction",staticClass:"call-to-action",class:{
        'call-to-action-atc': _vm.isATCButton,
        'call-to-action-atc-inline-carousel': _vm.isCarousel && !_vm.isFullScreen,
      },style:(_vm.bottomMargin)},[(_vm.isCtaButton)?_c('cta-button',{attrs:{"video-index":_vm.videoIndex},on:{"pause-video":function($event){return _vm.$emit('pause-video')}}},[_vm._v(" "+_vm._s(_vm.decodeTextPlayer(_vm.cta.text))+" ")]):(_vm.isATCButton && !_vm.isAtcOpened)?_c('div',{ref:"atcButtonWrapper",staticClass:"atc-button-wrapper",class:[
          {
            centered: !_vm.isAtcMultipleProducts,
            animate: _vm.animationStates[_vm.videoIndex],
            'carousel-inline-atc-button-wrapper': _vm.isCarousel && !_vm.isFullScreen,
            'inline-atc-button-wrapper': !_vm.isFullScreen && !_vm.isCarousel,
            'bubble-inline-atc-button-wrapper': _vm.isBubble && !_vm.isFullScreen,
          },
        ]},[_c('div',{ref:"atcButtonContainer",staticClass:"atc-button-container",class:[
            { 'atc-button-container-mobile': _vm.isMobile || !_vm.isFullScreen },
            { dragging: _vm.isDragging },
          ],on:{"mousedown":function($event){$event.stopPropagation();return _vm.startDrag.apply(null, arguments)},"touchstart":_vm.startDrag,"mousemove":_vm.handleDrag,"touchmove":_vm.handleDrag,"mouseup":_vm.endDrag,"touchend":_vm.endDrag,"mouseleave":_vm.endDrag}},_vm._l((_vm.cta.products),function(product,index){return _c('add-to-cart-button',{key:product._id,staticClass:"product-card",attrs:{"product":product},on:{"click":function($event){return _vm.toggleAtcFormVisibility(product, index)}}})}),1)]):(_vm.isEmailCollector)?_c('custom-input',{attrs:{"videoIndex":_vm.videoIndex}}):_vm._e()],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.showAtcForm)?_c('div',{staticClass:"atc-form"},[_c('AtcForm',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.toggleAtcFormVisibility),expression:"toggleAtcFormVisibility"}],attrs:{"video-index":_vm.videoIndex,"isManucurist":_vm.isManucurist,"product":_vm.cta.products[
              _vm.productButtonClicked !== null ? _vm.productButtonClicked : 0
            ]},on:{"close":function($event){return _vm.toggleAtcFormVisibility()},"added-to-cart":_vm.hideAtcForm}})],1):_vm._e()]),(_vm.showSwipeIndication && _vm.isMultiple && !_vm.isHorizontalSwipe)?_c('div',{staticClass:"swiping-indication"},[_c('Arrow'),_c('Arrow')],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }